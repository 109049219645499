<template>
  <div>
    <van-nav-bar title="91智能机刷卡后台" />
    <router-link to="/goods" class="link-card link-card_1"><img src="../assets/pic3.png" class="icon-img">收货刷卡</router-link>
    <router-link to="/inStorage" class="link-card link-card_2"><img src="../assets/pic2.png" class="icon-img">入库刷卡</router-link>
    <router-link to="/outStorage" class="link-card link-card_3"><img src="../assets/pic1.png" class="icon-img">出库刷卡</router-link>
  </div>
</template>
<script>

export default {
  
};
</script>
<style scoped>
  .link-card{
    height: 100px;
    border-radius: 8px;
    margin: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 18px;
  }
  .link-card_1{
    background-color: rgba(10, 159, 254, 1);
  }
  .link-card_2{
    background-color: rgba(9, 183, 124, 1);
  }
  .link-card_3{
    background-color: rgba(255, 156, 1, 1);
  }
  .icon-img{
    width: 48px;
    height: 48px;
    margin-right: 20px;
  }
</style>